.plant {
  background-color: #394f49e5;
  width: 30%;
  margin: 2% auto;
  padding: 2%;
  border-radius: 50px;
  color: white;
}

.plant button, a {
  color: #394f49e5;
  margin: 1%;
}

.plants-container {
  margin: 1%;
}

button, a {
  color: #394f49e5;
}

h1 {
  color: #394f49e5;
}