/* * {
  color: white;
} */
body {
  background-color: #ebebeb;
  background-image: url("./images/background5.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  /* width: 100%; */
}
.main {
  width: 30%;
  height: 100vh;
  background: rgba(86, 86, 86, 0.3);
  backdrop-filter: blur(10px); /*This gives the block out */
  position: absolute;
  left: 0;
  top: 0;
}
button {
  padding: 8px 25px;
  /* background: transparent; */
  outline: none;
  border: 2px solid #394f49;
  border-radius: 20px;
  color: black;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  transition: transform 1s;
  margin: 10px;
}
button:hover {
  opacity: 0.5;
  transform: scale(1.1);
}
a {
  text-decoration: none;
  color: black;
}
.furtherDown {
  margin-top: 50%;
}
.navbar {
  width: 20%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
}
.navbar .logo {
  width: 60px;
  margin: 30px 120px;
  cursor: pointer;
  transition: transform 1s;
}

.logo:hover {
  transform: rotate(-10deg);
}
