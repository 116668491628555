* {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}
/* .background-color {
  background-color: #ebebeb;
  background-image: url("./Styles/images/background5.jpg");
  background-repeat: no-repeat;
  /* background-position: top; */

.signupForm {
  width: 900px;
  text-align: center;
}

.signup {
  /* margin-bottom: -80px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  margin: 10px;
  padding: 10px;
  position: relative;
  letter-spacing: 1px;
  color: black;
  border: 2px solid #2e6559;
  border-radius: 4px;
  display: black;
  width: 90%;
  padding: 10px;
  font-size: 14px;
}
.signupMargin {
  margin-bottom: -80px;
}
